import { Injectable } from '@angular/core';
import { ErrorMessages } from '../models/data-catalogs';

@Injectable({
  providedIn: 'root'
})
export class ErrorParserService {

  constructor() { }

  catchAuthError(errors) {
    if (!errors) {
      return false;
    }

    return errors.graphQLErrors
          .some((err) => err.message === ErrorMessages.AUTH_ERROR) ||
          errors.networkError?.error?.errors
          .some((err) => err.message === ErrorMessages.AUTH_ERROR);
  }

  parseError(errors) {
    if (!errors) {
      return null;
    }
    const errorsIDs = [];
    if (errors.graphQLErrors) {
      errorsIDs.push(...errors.graphQLErrors.map(({ id }) => id));
    }
    if (errors.networkError && errors.networkError.error && errorsIDs) {
      errorsIDs.push(...errors.networkError.error.errors.map(({ id }) => id));
    }
    if (!errorsIDs.length) {
      return null;
    }

    return (`${errorsIDs[0]}${(errorsIDs.length > 1) ? (' - ' + errorsIDs[errorsIDs.length - 1]) : ''}`);
  }
}
