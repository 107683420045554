import gql from 'graphql-tag';
import { zoneReceivers } from './fragments';

export const loginWithPassword = gql`
  mutation loginWithPassword(
    $email: String!
    $password: String!
  ) {
    loginWithPassword(
      email: $email
      password: $password
    ) {
      loginToken
    }
  }`;

export const logout = gql`
  mutation logout {
    logout
  }`;

export const resetPassword = gql`
  mutation sendResetPasswordEmail(
    $email: String!
  ) {
    sendResetPasswordEmail(
    email: $email
    )
  }`;

export const assignReceiverToZone = gql`
  mutation assignReceiverToZone(
    $zoneId: Int!
    $activationCode: String!
    $description: String
  ) {
    assignReceiverToZone(
      zoneId: $zoneId
      activationCode: $activationCode
      description: $description
    )
  }
`;

export const getZoneReceivers = gql`
  query zone(
    $id: Int!
  ) {
    zone(id: $id) {
      id
      ...ZoneReceivers
    }
  }
${zoneReceivers}
`;

export const disconnectReceiver = gql`
  mutation unbindReceiverFromZone(
    $zoneId: Int!
    $receiverId: Int!
  ) {
    unbindReceiverFromZone(
      zoneId: $zoneId
      receiverId: $receiverId
    )
  }
`;

export const requestReceiverLogs = gql`
  mutation requestStreamReceiverLogs(
    $streamReceiverId: Int!
  ) {
    requestStreamReceiverLogs(
      streamReceiverId: $streamReceiverId
    )
  }
`
