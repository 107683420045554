import { Component, OnInit } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { Zone, ZoneStateInfo } from 'src/app/shared/models/models';
import { ZonesService } from 'src/app/shared/services/zones.service';

@Component({
  selector: 'app-receiver-logs',
  templateUrl: './receiver-logs.component.html',
  styleUrls: ['./receiver-logs.component.scss']
})
export class ReceiverLogsComponent implements OnInit {
  zones: Zone[];
  loading: boolean = true;

  constructor(
    private zoneService: ZonesService
  ) { }

  ngOnInit(): void {
    if (!this.zoneService.hasZones()) {
      this.zoneService.getZones().subscribe();
    }

    this.zoneService.zones$
    .pipe(
      tap((zones: Zone[]) => {
        if (!!zones) {
          this.loading = false;
        }
      }),
      filter((zones: Zone[]) => !!zones && !!zones.length)
    )
    .subscribe((zones) => {
      this.zones = zones;
    })
  }

  sendLogs(receiverId) {
    this.zoneService.requestReceiverLogs(receiverId)
    .subscribe();
  }

}
