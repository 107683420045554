import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';

import { SystemRoutingModule } from './system-routing.module';
import { SystemComponent } from './system.component';
import { HeaderComponent } from './header/header.component';
import { FooterMenuComponent } from './footer-menu/footer-menu.component';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { ActivateModule } from './activate/activate.module';
import { ZonesModule } from './zones/zones.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AudioModule } from './audio/audio.module';
import { VideoModule } from './video/video.module';
import { NetworkModule } from './network/network.module';
import { SystemViewModule } from './system-view/system-view.module';
import { CommandsDialogModule } from './commands-dialog/commands-dialog.module';
import { ZoneReceiversComponent } from './zone-receivers/zone-receivers.component';
import { ReceiverLogsModule } from './receiver-logs/receiver-logs.module';

@NgModule({
  declarations: [
    SystemComponent,
    HeaderComponent,
    FooterMenuComponent
  ],
  imports: [
    CommonModule,
    SystemRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    HomeModule,
    LoginModule,
    ActivateModule,
    ZonesModule,
    AudioModule,
    VideoModule,
    NetworkModule,
    SystemViewModule,
    ReceiverLogsModule,
    CommandsDialogModule
  ]
})

export class SystemModule { }
