import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACK_BAR_DURATION } from '../../models/data-catalogs';
import { ErrorParserService } from '../../services/error-parcing.service';
import { ZonesService } from '../../services/zones.service';
import { Clipboard } from '@angular/cdk/clipboard';

interface ICodeDialogData {
  zoneId: number;
}

@Component({
  selector: 'app-code-dialog',
  templateUrl: './code-dialog.component.html',
  styleUrls: ['./code-dialog.component.scss']
})
export class CodeDialogComponent implements OnInit {
  assignForm: FormGroup;
  zoneId: number;
  saveInProgress: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private zoneService: ZonesService,
    private dialog: MatDialog,
    private errorService: ErrorParserService,
    private clipboard: Clipboard,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: ICodeDialogData,
  ) { }

  ngOnInit(): void {
    this.assignForm = this.formBuilder.group({
      code: ['', Validators.required],
      description: ['', Validators.required]
    });

    if (this.data.zoneId) {
      this.zoneId = this.data.zoneId;
    }
  }

  get codeControl() {
    return this.assignForm.controls.code;
  }

  get descriptionControl() {
    return this.assignForm.controls.description;
  }

  assign() {
    if (this.assignForm.invalid) {
      return;
    }

    this.saveInProgress = true;

    this.zoneService.assignReceiverToZone(this.codeControl.value, this.zoneId, this.descriptionControl.value)
    .subscribe((res) => {
      this.saveInProgress = false;
      this._snackBar.open(`Success`, null, { duration: SNACK_BAR_DURATION });
      this.dialog.closeAll();
    },
    (err) => {
      this.saveInProgress = false;
      const errorString = this.errorService.parseError(err);
      if (errorString) {
        this._snackBar.open(
          `Error ID: ${errorString}`,
          'Copy',
          { duration: SNACK_BAR_DURATION })
        .onAction()
        .subscribe(() => {
          this.clipboard.copy(errorString);
        });
      } else {
        this._snackBar.open(`Connection failed`, null, { duration: SNACK_BAR_DURATION });
      }
    }
    );
  }

}
