import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommandKeys, SNACK_BAR_DURATION } from 'src/app/shared/models/data-catalogs';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { PlayerService } from 'src/app/shared/services/player.service';
import { SuccessAddedZoneComponent } from '../success-added-zone/success-added-zone.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormErrorMatcher } from 'src/app/shared/utils/form-error-matcher';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-zone',
  templateUrl: './add-zone.component.html',
  styleUrls: ['./add-zone.component.scss']
})
export class AddZoneComponent implements OnInit {
  public activateForm: FormGroup;
  activationInProgress: boolean = false;
  matcher = new FormErrorMatcher();

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private playerService: PlayerService,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.activateForm = this.formBuilder.group({
      code: ['', { validators: Validators.required}],
    });
  }

  get codeControl() {
    return this.activateForm.controls.code;
  }

  activate() {
    if (this.activateForm.invalid) {
      return;
    }
    this.activationInProgress = true;

    this.playerService
    .sendDeviceCommand(CommandKeys.ZONE_ACTIVATE, this.activateForm.controls.code.value)
    .pipe(
      finalize(() => this.activationInProgress = false)
    )
    .subscribe(
      (res) => {
        this.dialog.open(SuccessAddedZoneComponent);
      },
      (err) => {
        const errorString = this.errorService.parseError(err);
        if (errorString) {
          this._snackBar.open(
            `Error ID: ${errorString}`,
            'Copy',
            { duration: SNACK_BAR_DURATION })
          .onAction()
          .subscribe(() => {
            this.clipboard.copy(errorString);
          });
        } else {
          this._snackBar.open(`Player updating fails`, null, { duration: SNACK_BAR_DURATION });
        }
      }
    );
  }

}
