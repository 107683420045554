<ng-container *ngIf="!loading; else spinner">
  <div class="venues-wrapper" *ngIf="dataSource?.length; else noPlayer">
    <table class="venue-table" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Venue </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>

      <ng-container matColumnDef="serial">
        <th mat-header-cell *matHeaderCellDef> Serial </th>
        <td mat-cell *matCellDef="let element"> {{element.serial}} </td>
      </ng-container>

      <ng-container matColumnDef="zonesCount">
        <th mat-header-cell *matHeaderCellDef> Zones </th>
        <td mat-cell *matCellDef="let element"> {{element.zonesCount}} </td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef> Version </th>
        <td mat-cell *matCellDef="let element"> {{element.version}} </td>
      </ng-container>

      <ng-container matColumnDef="attention">
        <th mat-header-cell *matHeaderCellDef> Attention </th>
        <td mat-cell *matCellDef="let element"> {{element.attention}} </td>
      </ng-container>

      <ng-container matColumnDef="cacheCommit">
        <th mat-header-cell *matHeaderCellDef> Cache Commit </th>
        <td mat-cell *matCellDef="let element"> {{element.cacheCommit}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="savePlayer(row.id)"></tr>
    </table>
  </div>
</ng-container>

<div class="action-buttons">
  <button class="btn" (click)="activate()">Activate</button>
</div>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <mat-spinner [diameter]="80"></mat-spinner>
  </div>
</ng-template>

<ng-template #noPlayer>
  <div class="no-data">
    Currently, there are no active players. Please, activate a new one to see it in the list
  </div>
</ng-template>