<div class="form-wrapper">
  <div class="title">Network</div>
  <form [formGroup]="networkForm">
    <div class="header">
      <label class="item-label">Item</label>
      <label class="setting-label">Setting</label>
    </div>
    <div class="form-group">
      <label for="ip">IP</label>
      <input name="ip" inputmode="text" formControlName="ip" type="text" placeholder="IP">
      <div class="error-wrapper inline" *ngIf="networkForm.controls.ip.invalid && (networkForm.controls.ip.dirty || networkForm.controls.ip.touched)">
        <mat-error *ngIf="networkForm.controls.ip.hasError('pattern') && !networkForm.controls.ip.hasError('optionalRequired')">
          IP does not match with IP address pattern
        </mat-error>
        <mat-error *ngIf="networkForm.controls.ip.hasError('optionalRequired')">
          IP is required field
        </mat-error>
      </div>
    </div>
    <div class="form-group">
      <label for="dhcp">DHCP?</label>
      <mat-select name="dhcp" class="dhcp-select" formControlName="dhcp" (selectionChange)="updateFormValue()">
        <mat-option value="false"> No </mat-option>
        <mat-option value="true"> Yes </mat-option>
      </mat-select>
    </div>
    <div class="form-group">
      <label for="subnet">Subnet</label>
      <input name="subnet" inputmode="text" formControlName="subnet" type="text" placeholder="Subnet">
      <div class="error-wrapper inline" *ngIf="networkForm.controls.subnet.invalid && (networkForm.controls.subnet.dirty || networkForm.controls.subnet.touched)">
        <mat-error *ngIf="networkForm.controls.subnet.hasError('optionalRequired')">
          Subnet is required field
        </mat-error>
        <mat-error *ngIf="networkForm.controls.subnet.hasError('pattern') && !networkForm.controls.subnet.hasError('optionalRequired')">
          Subnet does not match with IP address pattern
        </mat-error>
      </div>
    </div>
    <div class="form-group">
      <label for="gateway">Gateway</label>
      <input name="gateway" inputmode="text" formControlName="gateway" type="text" placeholder="Gateway">
      <div class="error-wrapper inline" *ngIf="networkForm.controls.gateway.invalid && (networkForm.controls.gateway.dirty || networkForm.controls.gateway.touched)">
        <mat-error *ngIf="networkForm.controls.gateway.hasError('optionalRequired')">
          Gateway is required field
        </mat-error>
        <mat-error *ngIf="networkForm.controls.gateway.hasError('pattern') && !networkForm.controls.gateway.hasError('optionalRequired')">
          Gateway does not match with IP address pattern
        </mat-error>
      </div>
    </div>
    <div class="form-group">
      <label for="dns-a">DNS A</label>
      <input name="dns-a" inputmode="text" formControlName="dnsA" type="text" placeholder="DNS A">
      <div class="error-wrapper inline" *ngIf="networkForm.controls.dnsA.invalid && (networkForm.controls.dnsA.dirty || networkForm.controls.dnsA.touched)">
        <mat-error *ngIf="networkForm.controls.dnsA.hasError('required')">
          DNS A is required field
        </mat-error>
        <mat-error *ngIf="networkForm.controls.dnsA.hasError('pattern') && !networkForm.controls.dnsA.hasError('required')">
          DNS A does not match with IP address pattern
        </mat-error>
      </div>
    </div>
    <div class="form-group">
      <label for="dns-b">DNS B</label>
      <input name="dns-b" inputmode="text" formControlName="dnsB" type="text" placeholder="DNS B">
      <div class="error-wrapper inline" *ngIf="networkForm.controls.dnsB.invalid && (networkForm.controls.dnsB.dirty || networkForm.controls.dnsB.touched)">
        <mat-error *ngIf="networkForm.controls.dnsB.hasError('required')">
          DNS B is required field
        </mat-error>
        <mat-error *ngIf="networkForm.controls.dnsB.hasError('pattern') && !networkForm.controls.dnsB.hasError('required')">
          DNS B does not match with IP address pattern
        </mat-error>
      </div>
    </div>
    <div class="form-group">
      <label for="mtu">MTU</label>
      <input name="mtu" inputmode="text" formControlName="mtu" type="text" placeholder="MTU">
      <div class="error-wrapper inline" *ngIf="networkForm.controls.mtu.invalid && (networkForm.controls.mtu.dirty || networkForm.controls.mtu.touched)">
        <mat-error *ngIf="networkForm.controls.mtu.hasError('required')">
          MTU is required field
        </mat-error>
        <mat-error *ngIf="networkForm.controls.mtu.hasError('pattern') && !networkForm.controls.mtu.hasError('required')">
          MTU is not an integer
        </mat-error>
      </div>
    </div>
    <div class="form-group">
      <label>WG</label>
      <label>{{wgIp}}</label>
    </div>
  </form>
</div>
<div class="action-buttons">
  <button class="btn" [disabled]="networkForm.invalid" (click)="save()">Save</button>
</div>
