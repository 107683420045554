<footer id="footer">
  <div class="container">
    <ng-container *ngIf="mainMenu; else fullMenu">
      <div class="footer-menu-wrapper">
        <a routerLink="/home" routerLinkActive="active-link">
          <div class="menu-item-text">
            Home
          </div>
        </a>

        <a routerLink="/activate" routerLinkActive="active-link">
          <div class="menu-item-text">
            Activate
          </div>
        </a>
      </div>
    </ng-container>

    <button class="menu-button" (click)="logout()">
        Logout
    </button>
  </div>
</footer>
<ng-template #fullMenu>
  <div class="footer-menu-wrapper">
    <a routerLink="/home" routerLinkActive="active-link">
      <div class="menu-item-text">
        Home
      </div>
    </a>
  
    <a routerLink="/zones" routerLinkActive="active-link">
      <div class="menu-item-text">
        Zones
      </div>
    </a>
  
    <a routerLink="/audio" routerLinkActive="active-link">
      <div class="menu-item-text">
        Audio
      </div>
    </a>
  
    <a [class.disabled-link]="!hasVideoZone" routerLinkActive="active-link" [routerLink]="hasVideoZone ? '/video' : null">
      <div class="menu-item-text">
        Video
      </div>
    </a>
  
    <a routerLink="/network" routerLinkActive="active-link">
      <div class="menu-item-text">
        Network
      </div>
    </a>

    <a routerLink="/system" routerLinkActive="active-link">
      <div class="menu-item-text">
        System
      </div>
    </a>
  </div>
</ng-template>