import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommandsDialogComponent } from './commands-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    CommandsDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatTableModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CommandsDialogComponent
  ]
})
export class CommandsDialogModule { }
