import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZonesComponent } from './zones.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { AddZoneComponent } from './add-zone/add-zone.component';
import { SuccessAddedZoneComponent } from './success-added-zone/success-added-zone.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const routes = [{
    path: '',
    component: ZonesComponent
  },
  {
    path: 'activate',
    component: AddZoneComponent
  }
];

@NgModule({
  declarations: [
    ZonesComponent,
    AddZoneComponent,
    SuccessAddedZoneComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MatTableModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSnackBarModule
  ]
})
export class ZonesModule { }
