import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { CodeDialogComponent } from 'src/app/shared/components/code-dialog/code-dialog.component';
import {
  MediaType,
  normalizationCatalog,
  OUTPUT_REGULAR,
  PlayerSettingKeys,
  SNACK_BAR_DURATION
} from 'src/app/shared/models/data-catalogs';
import { AudioZoneSettings, DataCatalog } from 'src/app/shared/models/inner-model';
import { Player, PlayerSetting, Zone } from 'src/app/shared/models/models';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { PlayerService } from 'src/app/shared/services/player.service';
import { FormErrorMatcher } from 'src/app/shared/utils/form-error-matcher';
import { ZoneReceiversComponent } from '../zone-receivers/zone-receivers.component';
import { AudioLinkingComponent } from './audio-linking/audio-linking.component';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  playerSub: Subscription;

  videoForm: FormGroup;
  player: Player;
  zones: Zone[] = [];
  loading: boolean = true;
  saveInProgress: boolean = false;
  matcher = new FormErrorMatcher();

  normalizationCatalog: DataCatalog[] = normalizationCatalog;

  constructor(
    private playerService: PlayerService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private clipboard: Clipboard,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.videoForm = this.formBuilder.group({
      videoOutputs: this.formBuilder.array([]),
      normalization: ''
    });

    this.playerSub = this.playerService.player$
    .pipe(
      filter((player) => !!player),
    )
    .subscribe((player) => {
      this.loading = false;
      this.player = player;
      if (this.videoForm.untouched) {
        this.zones = player.zones.filter(({mediaType}) => mediaType !== MediaType.audio);
        this.videoOutputsArr.clear();
        this.zones.forEach((zone) => {
          const videoSetting: AudioZoneSettings = {
            output: ''
          };
          if (!!this.player.settings?.length) {
            videoSetting.output = this.player.settings
              .find((x) => x.zoneId === zone.id && x.setting === PlayerSettingKeys.VIDEO_OUTPUT)?.value || '';
          }
          this.videoOutputsArr.push(this.formBuilder.group({
            output: [videoSetting.output, {validators: Validators.pattern(OUTPUT_REGULAR)}]
          }));
        });

        if (!!this.player.settings?.length) {
          const norm = this.player.settings.find((x) => x.setting === PlayerSettingKeys.VIDEO_NORMALIZATION)?.value;
          this.videoForm.patchValue({
            normalization: norm || ''
          });
        }
      }
    });
  }

  get videoOutputsArr() {
    return (this.videoForm.get('videoOutputs') as FormArray);
  }


  save() {
    if (this.videoForm.invalid) {
      return;
    }

    this.saveInProgress = true;

    const settingsArr: PlayerSetting[] = [];
    this.videoOutputsArr.controls.forEach((control, index) => {
      settingsArr.push({
        zoneId: this.zones[index].id,
        setting: PlayerSettingKeys.VIDEO_OUTPUT,
        value: control.get('output').value
      });
    });

    settingsArr.push({
      setting: PlayerSettingKeys.VIDEO_NORMALIZATION,
      value: this.videoForm.get('normalization').value
    });

    this.playerService.setAllPlayerSettings(this.player.id, settingsArr)
    .pipe(
      tap(() => this.videoForm.markAsUntouched()),
      switchMap(() => this.playerService.getPlayer(this.player.id)),
      finalize(() => this.saveInProgress = false)
    )
    .subscribe(() => {
      this._snackBar.open(`Player updated successfully`, null, { duration: SNACK_BAR_DURATION });
    }, (err) => {
      const errorString = this.errorService.parseError(err);
      if (errorString) {
        this._snackBar.open(
          `Error ID: ${errorString}`,
          'Copy',
          { duration: SNACK_BAR_DURATION })
        .onAction()
        .subscribe(() => {
          this.clipboard.copy(errorString);
        });
      } else {
        this._snackBar.open(`Player updating fails`, null, { duration: SNACK_BAR_DURATION });
      }
    });
  }

  refresh() {
    this.loading = true;
    this.playerService.getPlayer(this.player.id)
    .pipe(
      tap(() => this.loading = false)
    )
    .subscribe();
  }

  viewAssignedReceivers(zone: Zone) {
    this.dialog.open(ZoneReceiversComponent, {
      data: {
        zone
      }
    });
  }

  assignAudio(zone: Zone) {
    const linkedZone = this.player.settings.find(({setting, zoneId}) => setting === PlayerSettingKeys.LINK_AUDIO && zoneId === zone.id)?.value || null;
    this.dialog.open(AudioLinkingComponent, {
      data: {
        zone,
        linkedZone
      }
    })
    .afterClosed()
    .subscribe(() => {
      this.playerService.getPlayer(this.player.id)
      .subscribe();
    });
  }
}
