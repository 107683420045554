<ng-container *ngIf="!loading; else spinner">
  <ul class="zones-list">
    <li *ngFor="let zone of zones" class="zone-item">
      <h4>{{zone.name}} - {{zone.id}}</h4>
      <ng-container *ngIf="zone.streamReceivers?.length">
        <ul class="receivers-list">
          <li *ngFor="let receiver of zone.streamReceivers" class="receiver-item">
            {{ receiver.description || receiver.token}} - {{receiver.id}}
            <button class="btn small" (click)="sendLogs(receiver.id)">Send logs</button>
          </li>
        </ul>
      </ng-container>
    </li>
  </ul>
</ng-container>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <mat-spinner [diameter]="80"></mat-spinner>
  </div>
</ng-template>
