import gql from 'graphql-tag';
import { playerFragment, zoneFragment } from './fragments';

export const activatePlayer = gql`
  mutation activatePlayerBySerialNumber(
    $serialNumber: String!
  ) {
    activatePlayerBySerialNumber(
      serialNumber: $serialNumber
    ) {
      ...PlayerData
    }
  }
${playerFragment}
`;

export const getZones = gql`
  query getZones(
    $id: Int!
  ) {
    player(
      id: $id
    ) {
      id
      zones {
        ...ZoneData
      }
    }
  }
${zoneFragment}
`;

export const playerHistory = gql`
  query accessablePlayers {
    accessablePlayers {
      ...PlayerData
    }
  }
${playerFragment}
`;

export const getPlayer = gql`
  query player(
    $id: Int!
  ) {
    player(id: $id) {
      ...PlayerData
    }
  }
${playerFragment}
`;

export const setZoneOutputs = gql`
  mutation setZoneAudioOutputs(
    $playerId: Int!
    $values: [AudioOutputInput!]!
  ) {
    setZoneAudioOutputs(
      playerId: $playerId
      values: $values
    )
  }
`;

export const setAllSettings = gql`
  mutation setPlayerSettings(
    $playerId: Int!
    $values: [PlayerSettingInput!]!
  ) {
    setPlayerSettings(
      playerId: $playerId
      values: $values
    )
  }
`;

export const sendDeviceCommand = gql`
  mutation addDeviceCommand(
    $playerId: Int
    $serialNumber: String
    $command: String!
    $value: String
  ) {
    addDeviceCommand(
      playerId: $playerId
      serialNumber: $serialNumber
      command: $command
      value: $value
    )
  }
`;

export const playerSubscription = gql`
  subscription deviceStateChanged (
    $playerIdList: [Int!]!
  ) {
    deviceStateChanged(
      playerIdList: $playerIdList
    ) {
      playerId
      affectedFields
      stats {
        key
        service
        value
      }
      settings {
        setting
        value
        # displayValue
        zoneId
      }
      zoneAudioOutputs {
        output
        zoneId
      }
      commands {
        command
        status
        description
      }
      player {
        ...PlayerData
      }
      zones
    }
  }
${playerFragment}
`;

export const cancelCommand = gql`
  mutation cancelDeviceCommand(
    $commandId: Int!
  ) {
    cancelDeviceCommand(
      commandId: $commandId
    )
  }
`;

export const zoneVideoTrackSub = gql`
  subscription zonePlayingStateChange(
    $playerId: Int!
  ) {
    zonePlayingStateChange(
      playerId: $playerId
    ) {
      zone {
        id
        name
      }
      track {
        id
        name
        artist {
          id
          name
        }
        album {
          id
          name
        }
      }
      isPlaying
    }
  }
`;
