<div class="content-wrapper">
  <div class="col statistic">
    <div class="title">Statistics</div>
    <div class="statistic-wrapper" *ngIf="!loadingStats; else spinner">
      <div class="stat-row" *ngFor="let stat of stats">
        <label class="item-name">{{ stat.description }}</label>
        <label class="item-value">{{ stat.value }}</label>
      </div>
    </div>
    <form [formGroup]="timezoneForm">
      <div class="timezone-block">
        <label class="timezone-title">Timezone</label>

        <mat-select class="timezone-select" formControlName="timezone">
          <mat-option *ngFor="let timezoneItem of timezoneCatalog" [value]="timezoneItem">
            {{timezoneItem}}
          </mat-option>
        </mat-select>
        <button class="btn" [disabled]="unavalableToSave || timezoneForm.untouched" (click)="saveTimezone()">Save</button>
      </div>
    </form>

  </div>
  <div class="col services">
    <div class="title">Services</div>
    <div class="service-wrapper" *ngIf="!loadingServiceStats; else spinner">
      <div class="service-row header">
        <label class="service-name">Service</label>
        <label class="service-status">Status</label>
        <label class="service-cpu">CPU</label>
      </div>
      <div class="service-row" *ngFor="let service of services">
        <label class="service-name">{{ service.name }}</label>
        <label class="service-status">{{ service.status || ''}}</label>
        <label class="service-cpu">{{ service.cpu || ''}}</label>
        <button class="btn small" (click)="restart(service.name)">Restart</button>
      </div>
      <div class="service-row all">
        <label class="service-name">ALL</label>
        <label class="service-status"></label>
        <label class="service-cpu"></label>
        <button class="btn small" (click)="restart('ALL')">Restart</button>
      </div>
    </div>
  </div>
</div>
<div class="action-buttons">
  <button class="btn" (click)="reboot()">Reboot Player</button>
  <button class="btn" [disabled]="!isUpdateAvailable" (click)="update()">Update</button>
</div>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template>
