import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MediaType, PlayerSettingKeys, SNACK_BAR_DURATION } from 'src/app/shared/models/data-catalogs';
import { Zone } from 'src/app/shared/models/models';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { PlayerService } from 'src/app/shared/services/player.service';
import { Clipboard } from '@angular/cdk/clipboard';

interface IAudioLinkingData {
  zone: Zone;
  linkedZone?: number;
}

@Component({
  selector: 'app-audio-linking',
  templateUrl: './audio-linking.component.html',
  styleUrls: ['./audio-linking.component.scss']
})
export class AudioLinkingComponent implements OnInit {
  playerId: number;
  audioZones: Zone[];
  playerSub: Subscription;
  selectedZone: number = null;
  linkedZone: Map<number, string> = new Map<number, string>();

  constructor(
    private playerService: PlayerService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private clipboard: Clipboard,
    @Inject(MAT_DIALOG_DATA) public data: IAudioLinkingData
  ) { }

  ngOnInit(): void {
    this.playerSub = this.playerService.player$
    .pipe(
      filter((player) => !!player),
    )
    .subscribe((player) => {
      this.playerId = player.id;
      this.audioZones = player.zones.filter(({mediaType}) => mediaType === MediaType.audio);
      this.audioZones.forEach(({id}) => {
        const link = player.settings.filter(({setting, value}) => setting === PlayerSettingKeys.LINK_AUDIO && +value === id).map(({zoneId}) => zoneId);

        this.linkedZone.set(
          id,
          !!link ?
          player.zones
            .filter(({id}) => link.some((linkedId) => linkedId === id))
            .map(({name}) => name).join(', ')
          : 'Not Linked'
        );
      })
    });

    if (this.data.linkedZone) {
      this.selectedZone = +this.data.linkedZone;
    }
  }

  get isZoneLinkingChanged() {
    return (!this.data.linkedZone && !!this.selectedZone) ||
      (+this.data.linkedZone !== this.selectedZone && !!this.data.linkedZone);
  }

  get isDisconnected() {
    return !!this.data.linkedZone && !this.selectedZone;
  }

  selectZone(id: number) {
    if (this.selectedZone !== id) {
      this.selectedZone = id;
    } else {
      this.selectedZone = null;
    }
  }

  assignAudio() {
    this.playerService.setAllPlayerSettings(this.playerId,[{
      setting: PlayerSettingKeys.LINK_AUDIO,
      zoneId: this.data.zone.id,
      value: `${this.selectedZone || ''}`
    }])
    .subscribe(
      () => {
        this._snackBar.open(`Audio zone successfully assigned`, null, { duration: SNACK_BAR_DURATION });
        this.dialog.closeAll();
      },
      (err) => {
        const errorString = this.errorService.parseError(err);
        if (errorString) {
          this._snackBar.open(
            `Error ID: ${errorString}`,
            'Copy',
            { duration: SNACK_BAR_DURATION })
          .onAction()
          .subscribe(() => {
            this.clipboard.copy(errorString);
          });
        } else {
          this._snackBar.open(`Player updating fails`, null, { duration: SNACK_BAR_DURATION });
        }
      }
    );
  }

}
