<div class="video-wrapper">
  <div class="title">Video Output Assignment</div>
  <form [formGroup]="videoForm">
    <ng-container *ngIf="!loading; else spinner">
      <div class="zone-wrapper" *ngIf="zones?.length; else noActiveZone">
        <div class="video-output-row header">
          <label class="zone-label">Zone</label>
          <label class="output-label">Output</label>
          <label class="receivers-label">Receivers</label>
          <label class="audio-label">Assign audio</label>
        </div>
        <div formArrayName="videoOutputs">
          <div *ngFor="let alias of videoOutputsArr.controls; let i = index">
            <div [formGroup]="alias" class="video-output-row">
              <label class="zone-name">{{zones[i].name}}</label>
              <input matInput [errorStateMatcher]="matcher" class="output-input" type="text" formControlName="output">
              <button class="receiver-btn" (click)="viewAssignedReceivers(zones[i])">View</button>
              <button (click)="assignAudio(zones[i])">Assign audio</button>
              <div class="error-wrapper" *ngIf="alias.controls.output.invalid && (alias.controls.output.dirty || alias.controls.output.touched)">
                <mat-error *ngIf="alias.controls.output.hasError('pattern')">
                  Video output not match with pattern. Example, RTSP://0.0.0.0:8000/path
                </mat-error>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="normalization-block">
      <label class="normalization-title">Loudness Normalisation Mode</label>

      <mat-select class="normalization-select" formControlName="normalization">
        <mat-option *ngFor="let normalization of normalizationCatalog" [value]="normalization.value">
          {{normalization.name}}
        </mat-option>
      </mat-select>
    </div>
  </form>
</div>

<div class="action-buttons">
  <button class="btn" (click)="refresh()">Refresh</button>
  <button class="btn" [disabled]="videoForm.invalid || saveInProgress" (click)="save()">Save</button>
</div>

<ng-template #spinner>
  <div class="spinner-wrapper small">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</ng-template>

<ng-template #noActiveZone>
  <div class="no-data">
    This player doesn't have active zone
  </div>
</ng-template>

