import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Command } from 'src/app/shared/models/models';
import { PlayerService } from 'src/app/shared/services/player.service';

@Component({
  selector: 'app-commands-dialog',
  templateUrl: './commands-dialog.component.html',
  styleUrls: ['./commands-dialog.component.scss']
})
export class CommandsDialogComponent implements OnInit {
  loading: boolean;
  dataSource: Command[];
  displayedColumns: string[] = [
    'name',
    'value',
    'status',
    'state'
  ];
  playerSub: Subscription;

  constructor(
    private playerService: PlayerService
  ) { }

  ngOnInit(): void {
    this.playerSub = this.playerService.player$
    .pipe(
      filter((player) => !!player),
    )
    .subscribe((player) => {
      this.loading = false;
      this.dataSource = player.deviceCommands
        .sort((current, next) => current.id > next.id ? -1 : 1);
    });
  }

  cancelCommand(id) {
    this.playerService.cancelCommand(id)
    .subscribe();
  }

}
