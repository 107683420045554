import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceiverLogsComponent } from './receiver-logs.component';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const routes = [{
  path: '',
  component: ReceiverLogsComponent
}];

@NgModule({
  declarations: [
    ReceiverLogsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatProgressSpinnerModule,
  ]
})
export class ReceiverLogsModule { }
