import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodeDialogComponent } from './code-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ClipboardModule } from '@angular/cdk/clipboard';


@NgModule({
  declarations: [CodeDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSnackBarModule,
    ClipboardModule
  ],
  exports: [
    CodeDialogComponent
  ]
})
export class CodeDialogModule { }
