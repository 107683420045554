import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { CommandKeys, DEFAULT_TIMEZONE, PlayerSettingKeys, SNACK_BAR_DURATION, StatsKeys, timezonesValues } from 'src/app/shared/models/data-catalogs';
import { Player } from 'src/app/shared/models/models';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { PlayerService } from 'src/app/shared/services/player.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPlayerService, StatData } from 'src/app/shared/models/inner-model';

@Component({
  selector: 'app-system-view',
  templateUrl: './system-view.component.html',
  styleUrls: ['./system-view.component.scss']
})
export class SystemViewComponent implements OnInit, OnDestroy {
  timezoneForm: FormGroup;
  services: IPlayerService[] = [];
  stats: StatData[] = [];
  playerSub: Subscription;
  player: Player;
  loadingServiceStats: boolean = true;
  loadingStats: boolean = true;
  isUpdateAvailable: boolean;
  unavalableToSave: boolean = false;

  constructor(
    private playerService: PlayerService,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private clipboard: Clipboard,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.timezoneForm = this.formBuilder.group({
      timezone: ['', {validators: Validators.required} ]
    });

    this.playerSub = this.playerService.player$
    .pipe(
      filter((player) => !!player),
    )
    .subscribe((player) => {
      this.player = player;
      this.isUpdateAvailable = this.player.needDeviceUpdate;
      this.player.deviceStats.filter((stat) => !!stat.service).forEach((stat) => {
          const service = this.services.find((x) => x.name === stat.service);
          if (!service) {
            this.services.push({
              name: stat.service,
              status: stat.key === StatsKeys.STATUS ? stat.value : '',
              cpu: stat.key === StatsKeys.CPU ? stat.value : ''
            });
          } else {
            service[`${stat.key}`] = stat.value;
          }
      });
      this.loadingServiceStats = false;

      if (!!this.player.settings?.length) {
        const timezone = this.player.settings.find(({setting}) => setting === PlayerSettingKeys.TIMEZONE);
        if (timezone?.value) {
          this.timezoneForm.patchValue({timezone: timezone?.value});
        } else {
          const timezoneDefault = this.player.deviceStats.find(({key}) => key === StatsKeys.TIMEZONE);
          this.timezoneForm.patchValue({timezone: timezoneDefault?.value || DEFAULT_TIMEZONE});
        }
      }

      this.stats = this.player.deviceStats
        .filter((stat) => !stat.service && stat.key !== StatsKeys.WG)
        .map((stat): StatData => ({ name: this.statsNameFromKey(stat.key), value: stat.value, description: stat.description}));
      this.loadingStats = false;
    });
  }

  get timezoneCatalog() {
    return timezonesValues;
  }

  saveTimezone() {
    if (this.timezoneForm.invalid) {
      return;
    }

    this.unavalableToSave = true;
    const settingArr = [{
      setting: PlayerSettingKeys.TIMEZONE,
      value: this.timezoneForm.get('timezone').value
    }];

    this.playerService.setAllPlayerSettings(this.player.id, settingArr)
    .pipe(
      tap(() => this.timezoneForm.markAsUntouched()),
      switchMap(() => this.playerService.getPlayer(this.player.id)),
      finalize(() => this.unavalableToSave = false)
    )
    .subscribe(() => {
      this._snackBar.open(`Timezone updated successfully`, null, { duration: SNACK_BAR_DURATION });
    }, (err) => {
      const errorString = this.errorService.parseError(err);
      if (errorString) {
        this._snackBar.open(
          `Error ID: ${errorString}`,
          'Copy',
          { duration: SNACK_BAR_DURATION })
        .onAction()
        .subscribe(() => {
          this.clipboard.copy(errorString);
        });
      } else {
        this._snackBar.open(`Player updating fails`, null, { duration: SNACK_BAR_DURATION });
      }
    });
  }

  statsNameFromKey(name: string) {
    return name.replace('_', ' ');
  }

  reboot() {
    this.playerService.sendDeviceCommand(CommandKeys.REBOOT)
    .subscribe(
      () => {
        this._snackBar.open(`Player rebooted successfully`, null, { duration: SNACK_BAR_DURATION });
      },
      (err) => {
        const errorString = this.errorService.parseError(err);
        if (errorString) {
          this._snackBar.open(
            `Error ID: ${errorString}`,
            'Copy',
            { duration: SNACK_BAR_DURATION })
          .onAction()
          .subscribe(() => {
            this.clipboard.copy(errorString);
          });
        }
      });
  }

  update() {
    this.playerService.sendDeviceCommand(CommandKeys.UPDATE)
    .subscribe(
      () => {
        this._snackBar.open(`Player updated successfully`, null, { duration: SNACK_BAR_DURATION });
      },
      (err) => {
        const errorString = this.errorService.parseError(err);
        if (errorString) {
          this._snackBar.open(
            `Error ID: ${errorString}`,
            'Copy',
            { duration: SNACK_BAR_DURATION })
          .onAction()
          .subscribe(() => {
            this.clipboard.copy(errorString);
          });
        }
      });
  }

  restart(name) {
    this.playerService.sendDeviceCommand(CommandKeys.RESTART_SERVICE, name)
    .subscribe(
      () => {
        this._snackBar.open(`Service ${name} restarted`, null, { duration: SNACK_BAR_DURATION });
      },
      (err) => {
        const errorString = this.errorService.parseError(err);
        if (errorString) {
          this._snackBar.open(
            `Error ID: ${errorString}`,
            'Copy',
            { duration: SNACK_BAR_DURATION })
          .onAction()
          .subscribe(() => {
            this.clipboard.copy(errorString);
          });
        }
      });
  }

  ngOnDestroy() {
    this.playerSub.unsubscribe();
  }

}
