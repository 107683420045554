import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { PlayerService } from 'src/app/shared/services/player.service';
import { CommandsDialogComponent } from '../commands-dialog/commands-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  checkSub: Subscription;
  hasUncheckedCommand: boolean = false;

  constructor(
    private playerService: PlayerService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.checkSub = this.playerService.hasUncheckedCommands$
    .subscribe((hasUncheckedCommand) => {
      this.hasUncheckedCommand = hasUncheckedCommand;
    });
  }

  openCommandsDialog() {
    this.dialog.open(CommandsDialogComponent)
    .afterOpened()
    .pipe(
      delay(3000)
    )
    .subscribe(() => {
      this.playerService.setCommandCheckStatus(false);
    })
  }

  ngOnDestroy(): void {
    this.checkSub.unsubscribe();
  }

}
