import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { PlayerActivationGuard } from '../shared/guards/player-activation.guard';

import { SystemComponent } from './system.component';

const routes: Routes = [
  {
    path: '',
    component: SystemComponent,
    children: [
      {
        path: 'login',
        loadChildren: './login/login.module#LoginModule'
      },
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: './home/home.module#HomeModule'
      },
      {
        path: 'activate',
        canActivate: [AuthGuard],
        loadChildren: './activate/activate.module#ActivateModule'
      },
      {
        path: 'zones',
        canActivate: [AuthGuard, PlayerActivationGuard],
        loadChildren: './zones/zones.module#ZonesModule'
      },
      {
        path: 'audio',
        canActivate: [AuthGuard, PlayerActivationGuard],
        loadChildren: './audio/audio.module#AudioModule'
      },
      {
        path: 'video',
        canActivate: [AuthGuard, PlayerActivationGuard],
        loadChildren: './video/video.module#VideoModule'
      },
      {
        path: 'network',
        canActivate: [AuthGuard, PlayerActivationGuard],
        loadChildren: './network/network.module#NetworkModule'
      },
      {
        path: 'system',
        canActivate: [AuthGuard, PlayerActivationGuard],
        loadChildren: './system-view/system-view.module#SystemViewModule'
      },
      {
        path: 'logs',
        canActivate: [AuthGuard, PlayerActivationGuard],
        loadChildren: './receiver-logs/receiver-logs.module#ReceiverLogsModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class SystemRoutingModule {
}
