import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { loginWithPassword, logout, resetPassword } from '../gql/consts';
import { LocalStoreService } from './localstore.service';

import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { PlayerService } from './player.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuthorized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private apollo: Apollo,
    private localstoreService: LocalStoreService,
    private playerService: PlayerService
  ) { }

  public isAuthenticated(): boolean {
    const token = this.localstoreService.getItem('authToken');
    this.isAuthorized$.next(!!token);

    return !!token;
  }

  login(email: string, password: string) {
    return this.apollo.mutate({
        mutation: loginWithPassword,
        variables: { email, password }
      })
      .pipe(
        map((data: any) => data.data.loginWithPassword),
        tap((authData) => {
          this.localstoreService.setItem('authToken', authData.loginToken);
          this.isAuthorized$.next(true);
        })
      );
  }

  logout() {
    return this.apollo.mutate({
        mutation: logout
      })
      .pipe(
        tap(() => {
          this.localstoreService.clear();
          this.isAuthorized$.next(false);
          this.playerService.clear();
        })
      );
  }

  resetPassword(email: string) {
    return this.apollo.mutate({
      mutation: resetPassword,
      variables: { email }
    });
  }
}
