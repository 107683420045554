<div class="dialog-header">
  <h2 mat-dialog-title>Success</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>
<mat-dialog-content>
  <div class="alert-text"> Default settings were successfully saved </div>
</mat-dialog-content>
<div mat-dialog-actions class="action-btns">
  <button mat-dialog-close class="btn" (click)="addZones()">Add zones</button>
  <button mat-dialog-close class="btn" (click)="manage()">Manage</button>
</div>
