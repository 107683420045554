<div class="form-wrapper">
  <div class="title">Activate Zone</div>
  <form [formGroup]="activateForm" (ngSubmit)="activate()">
    <div class="form-group" [class.with-error]="codeControl.invalid && (codeControl.dirty || codeControl.touched)">
      <label for="activation-code">Zone Activation Code</label>
      <input matInput [errorStateMatcher]="matcher" id="activation-code" inputmode="text" formControlName="code" type="text" placeholder="Zone Activation Code">
    </div>
    <div class="error-wrapper" *ngIf="codeControl.invalid && (codeControl.dirty || codeControl.touched)">
      <mat-error *ngIf="codeControl.hasError('required')">
        Activation Code is required
      </mat-error>
    </div>
    <div class="control-buttons">
      <button type="submit" [disabled]="activateForm.invalid || activationInProgress" class="btn">Activate</button>
    </div>
  </form>
</div>
