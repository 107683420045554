import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { assignReceiverToZone, disconnectReceiver, getZoneReceivers, requestReceiverLogs } from '../gql/consts';
import { getZones, zoneVideoTrackSub } from '../gql/player-consts';
import { Zone, ZoneStateInfo } from '../models/models';
import { LocalStoreService } from './localstore.service';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {
  zones: Zone[];
  zones$: BehaviorSubject<Zone[]> = new BehaviorSubject<Zone[]> (null);

  constructor(
    private apollo: Apollo,
    private localstoreService: LocalStoreService
  ) { }

  getZones(playerId: number = +this.localstoreService.getItem('playerId')) {
    return this.apollo.query({
      query: getZones,
      variables: { id: playerId },
      fetchPolicy: 'no-cache'
    })
    .pipe(
      map((data: any) => data.data.player.zones),
      tap((zones: Zone[]) => {
        this.zones = zones;
        this.zones$.next(this.zones);
      })
    );
  }

  setZones(zones: Zone[]) {
    this.zones = zones;
    this.zones$.next(this.zones);
  }

  assignReceiverToZone(activationCode: string, zoneId: number, description: string) {
    return this.apollo.mutate({
      mutation: assignReceiverToZone,
      variables: {
        activationCode,
        zoneId,
        description
      }
    })
    .pipe(
      map((data: any) => data.data.assignReceiverToZone)
    )
  }

  hasZones(): boolean {
    return !!this.zones;
  }

  clear() {
    this.zones = null;
    this.zones$.next(this.zones);
  }

  zonePlayingStateChange() {
    const playerId: number = +(this.localstoreService.getItem('playerId') || -1);
    return this.apollo.subscribe({
      query: zoneVideoTrackSub,
      variables: { playerId }
    })
    .pipe(
      map((data: any) => data.data.zonePlayingStateChange)
    )
    .subscribe((zoneState: ZoneStateInfo) => {
      if (!!this.zones) {
        const zones = [...this.zones];
        const zoneIndex = zones.findIndex(({id, currentTrack}) => id === zoneState.zone.id && currentTrack?.id !== zoneState.track?.id);
        if (zoneIndex >= 0) {
          zones[zoneIndex] = {
            ...zones[zoneIndex],
            currentTrack: zoneState.track,
            playing: zoneState.isPlaying
          };
          this.zones = zones;
          this.zones$.next(zones);
        }
      }
    });
  }

  getZoneReceivers(zoneId: number) {
    return this.apollo.query({
      query: getZoneReceivers,
      variables: { id: zoneId },
      fetchPolicy: 'no-cache'
    })
    .pipe(
      map((data: any) => data.data.zone)
    );
  }

  disconnectReceiver(receiverId: number, zoneId: number) {
    return this.apollo.mutate({
      mutation: disconnectReceiver,
      variables: {
        receiverId,
        zoneId
      }
    })
    .pipe(
      map((data: any) => data.data.unbindReceiverFromZone)
    );
  }

  requestReceiverLogs(receiverId: number) {
    return this.apollo.mutate({
      mutation: requestReceiverLogs,
      variables: { streamReceiverId: receiverId}
    })
    .pipe(
      map((data: any) => data.data.requestStreamReceiverLogs)
    );
  }
}
