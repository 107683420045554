<div class="dialog-header">
  <h2 mat-dialog-title>{{data.zone.name}} Assign audio zone</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>
<ng-container>
  <div class="header" *ngIf="!!audioZones.length;">
    <p>Audio Zone</p>
    <p>Linked To</p>
    <p>Select</p>
  </div>
    <ul *ngIf="!!audioZones.length; else noZones" class="zone-list">
      <li
        *ngFor="let zone of audioZones"
        (click)="selectZone(zone.id)"
        [ngClass]="{
          'selected': zone.id === selectedZone
        }"
        class="zone-item"
      >
        <p class="zone-name text-truncate">{{ zone.name }}</p>
        <p class="zone-name text-truncate">{{ linkedZone.get(zone.id) }}</p>
        <div class="checkmark-container"></div>
      </li>
    </ul>
</ng-container>
<div mat-dialog-actions class="action-btns">
  <button class="btn assign-btn" [disabled]="!isZoneLinkingChanged" (click)="assignAudio()">{{isDisconnected ? 'Disconnect' : 'Assign Audio'}}</button>
  <button mat-dialog-close class="btn">Cancel</button>
</div>
<ng-template #noZones>
  <div class="no-zones-message text-uppercase">No Audio Zones available</div>
</ng-template>

