import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { PlayerService } from '../shared/services/player.service';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit, OnDestroy {
  showFooter: boolean;
  authSub: Subscription;

  constructor(
    private authService: AuthService,
    private playerService: PlayerService
  ) { }

  ngOnInit(): void {
    this.authSub = this.authService.isAuthorized$.subscribe((isAuth) => {
      this.showFooter = isAuth;
    });

    this.playerService.getSavedPlayer();
  }

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
  }

}
