import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { CodeDialogComponent } from 'src/app/shared/components/code-dialog/code-dialog.component';
import { StreamReceiver, Zone } from 'src/app/shared/models/models';
import { ZonesService } from 'src/app/shared/services/zones.service';

interface IZoneReceiverData {
  zone: Zone;
}

@Component({
  selector: 'app-zone-receivers',
  templateUrl: './zone-receivers.component.html',
  styleUrls: ['./zone-receivers.component.scss']
})
export class ZoneReceiversComponent implements OnInit {
  zone: Zone;
  receivers: StreamReceiver[];
  loading: boolean = true;

  constructor(
    private zoneService: ZonesService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: IZoneReceiverData
  ) { }

  ngOnInit(): void {
    this.zone = this.data.zone;
    this.zoneService.getZoneReceivers(this.zone.id)
    .pipe(
      tap(() => this.loading = false)
    )
    .subscribe((zone: Zone) => {
      this.receivers = zone.streamReceivers;
    });
  }

  assignReceiver() {
    this.dialog.open(CodeDialogComponent, {
      data: {
        zoneId: this.zone.id
      }
    });
  }

  disconnectReceiver(receiverId: number) {
    this.zoneService.disconnectReceiver(receiverId, this.zone.id)
    .subscribe((res) => {
      if (res) {
        this.receivers = this.receivers.filter(({id}) => id !== receiverId);
      }
    })
  }

}
