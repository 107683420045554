import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, from, InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

  // TODO
  // connect if we will need websocket
  const webSocketLink = new WebSocketLink({
    uri: environment.GRAPHQL_WS_URL,
    options: {
      lazy: true,
      timeout: 5000,
      reconnect: true,
      connectionParams: () => {
        const token = localStorage.getItem('authToken');

        if (!!token) {
          return {
            authorization: token ? `Bearer ${token}` : '',
          };
        }
      }
    },
  });

  const errorAfterware = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((err: any) =>
        console.log(
          `id ${err.id} [GraphQL error]: Message: ${err.message}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      console.log(networkError);

    }

    // remove token and player id if token is expired
    if (graphQLErrors?.some((error) => !!error?.message && error?.message === "Not logged in") || networkError?.message === "Not logged in") {
      localStorage.removeItem('authToken');
      localStorage.removeItem('playerId');
    }
  });

  // config main API link
  const http = httpLink.create({ uri: environment.API_URL });

  // add Authorization token
  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('authToken');

    if (!!token) {
      operation.setContext({
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
      });
    }

    return forward(operation);
  });

  const link = split(
    // split based on operation type
    ({query}) => {
      const res = getMainDefinition(query);
      return (
        res.kind === 'OperationDefinition' && res.operation === 'subscription'
      );
    },
    webSocketLink,
    http
  );

  return {
    link: from([errorAfterware, authMiddleware, link]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        errorPolicy: 'all'
      }
    }
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
