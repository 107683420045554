import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-failed-activation',
  templateUrl: './failed-activation.component.html',
  styleUrls: ['./failed-activation.component.scss']
})
export class FailedActivationComponent implements OnInit {
  serialNumber: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data.serialNumber) {
      this.serialNumber = this.data.serialNumber;
    }
  }

}
