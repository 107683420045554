import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MediaType } from 'src/app/shared/models/data-catalogs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LocalStoreService } from 'src/app/shared/services/localstore.service';
import { PlayerService } from 'src/app/shared/services/player.service';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss']
})
export class FooterMenuComponent implements OnInit {
  mainMenu: boolean = true;
  hasVideoZone: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private playerService: PlayerService
  ) { }

  ngOnInit(): void {
    this.playerService.player$
    .subscribe((player) => {
      this.mainMenu = !player;
      this.hasVideoZone = player?.zones?.some(({mediaType}) => mediaType === MediaType.video || mediaType === MediaType.channel)
    });
  }

  logout() {
    this.authService.logout()
    .subscribe(
      () => {
        this.router.navigate(['/login']);
      },
      (error) => {
        console.error(error);
      }
    );

  }

}
