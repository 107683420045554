import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormErrorMatcher } from 'src/app/shared/utils/form-error-matcher';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { SNACK_BAR_DURATION } from 'src/app/shared/models/data-catalogs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginInProgress: boolean = false;

  matcher = new FormErrorMatcher();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private clipboard: Clipboard,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', { validators: [Validators.required, Validators.email]}],
      password: ['', { validators: Validators.required}]
    });
  }

  get emailControl() {
    return this.loginForm.controls.email;
  }

  get passwordControl() {
    return this.loginForm.controls.password;
  }

  login() {
    if (this.loginForm.invalid) {
      return;
    }

    this.loginInProgress = true;
    this.authService.login(this.emailControl.value, this.passwordControl.value)
    .pipe(
      finalize(() => this.loginInProgress = false)
    )
    .subscribe(
      () => {
        this.router.navigate(['/home']);
      },
      (error) => {
        console.error(error);
        if (this.errorService.catchAuthError(error)) {
          this.dialog.open(AlertDialogComponent, {
            data: {
              type: 'error',
              message: 'Incorrect email or password!'
            }
          });
        } else {
          const errorString = this.errorService.parseError(error);
          if (errorString) {
            this._snackBar.open(
              `Error ID: ${errorString}`,
              'Copy',
              { duration: SNACK_BAR_DURATION })
            .onAction()
            .subscribe(() => {
              this.clipboard.copy(errorString);
            });
          } else {
            this._snackBar.open(`Login failed`, null, { duration: SNACK_BAR_DURATION });
          }
        }
      }
    );
  }

}
