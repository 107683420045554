import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivateComponent } from './activate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { SuccessActivationComponent } from './success-activation/success-activation.component';
import { FailedActivationComponent } from './failed-activation/failed-activation.component';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSelectModule } from '@angular/material/select';

const routes = [{
  path: '',
  component: ActivateComponent
}];

@NgModule({
  declarations: [
    ActivateComponent,
    SuccessActivationComponent,
    FailedActivationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatSelectModule,
    ClipboardModule
  ],
  exports: [
    SuccessActivationComponent,
    FailedActivationComponent
  ]
})
export class ActivateModule { }
