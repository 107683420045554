<div class="form-wrapper">
  <ng-container *ngIf="view === activationView.PLAYER; else settingsTemplate">
  <div class="title">Activate Player</div>
    <form [formGroup]="activateForm" (ngSubmit)="activate()">
      <div class="form-group" [class.with-error]="serialNumberControl.invalid && (serialNumberControl.dirty || serialNumberControl.touched)">
        <label for="serial-number">Serial Number</label>
        <input matInput [errorStateMatcher]="matcher" id="serial-number" inputmode="text" formControlName="serialNumber" type="text" placeholder="Serial Number">
      </div>
      <div class="error-wrapper" *ngIf="serialNumberControl.invalid && (serialNumberControl.dirty || serialNumberControl.touched)">
        <mat-error *ngIf="serialNumberControl.hasError('required')">
          Serial Number is required
        </mat-error>
      </div>
      <div class="control-buttons">
        <button type="submit" [disabled]="activateForm.invalid || activationInProgress" class="btn">Activate</button>
      </div>
    </form>
  </ng-container>
  <ng-template #settingsTemplate>
    <div class="title">Set default settings</div>
    <div class="info">
      The player with serial number {{serialNumberControl.value}} was successfully activate
    </div>
    <form [formGroup]="settingsForm" (ngSubmit)="saveSettings()">
      <div class="form-group">
        <label class="normalization-title large">Audio Normalisation</label>
  
        <mat-select class="normalization-select" formControlName="audioNormalization">
          <mat-option *ngFor="let normalization of normalizationCatalog" [value]="normalization.value" [disabled]="normalization.isDisabled">
            {{normalization.name}}
          </mat-option>
        </mat-select>
      </div>
      <div class="form-group">
        <label class="normalization-title large">Video Normalisation</label>
  
        <mat-select class="normalization-select" formControlName="videoNormalization">
          <mat-option *ngFor="let normalization of normalizationCatalog" [value]="normalization.value" [disabled]="normalization.isDisabled">
            {{normalization.name}}
          </mat-option>
        </mat-select>
      </div>
      <div class="form-group">
        <label class="timezone-title large">Timezone</label>

        <mat-select class="timezone-select" formControlName="timezone">
          <mat-option *ngFor="let timezoneItem of timezoneCatalog" [value]="timezoneItem">
            {{timezoneItem}}
          </mat-option>
        </mat-select>
      </div>
      <div class="control-buttons">
        <button type="submit" [disabled]="settingsForm.invalid || saveInProgress" class="btn">Save</button>
      </div>
    </form>
  </ng-template>
</div>