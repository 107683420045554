<div class="dialog-header">
  <h2 mat-dialog-title>Assign receiver to zone</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>
<mat-dialog-content class="mat-dialog-content">
  <form [formGroup]="assignForm">
    <div class="form-group" [class.with-error]="codeControl.invalid && (codeControl.dirty || codeControl.touched)">
      <label for="code">Code</label>
      <input matInput id="code" inputmode="text" formControlName="code" type="text" placeholder="Code" />
    </div>
    <div class="error-wrapper" *ngIf="codeControl.invalid && (codeControl.dirty || codeControl.touched)">
      <mat-error *ngIf="codeControl.hasError('required')">
        Code is required
      </mat-error>
    </div>
    <div class="form-group" [class.with-error]="descriptionControl.invalid && (descriptionControl.dirty || descriptionControl.touched)">
      <label for="description">Name</label>
      <input matInput id="description" inputmode="text" formControlName="description" type="text" placeholder="Receiver name" />
    </div>
    <div class="error-wrapper" *ngIf="descriptionControl.invalid && (descriptionControl.dirty || descriptionControl.touched)">
      <mat-error *ngIf="descriptionControl.hasError('required')">
        Receiver name is required
      </mat-error>
    </div>
  </form>
</mat-dialog-content>
<div mat-dialog-actions class="action-btns">
  <button mat-dialog-close class="btn primary">Cancel</button>
  <button (click)="assign()" [disabled]="assignForm.invalid || saveInProgress" class="btn">Assign</button>
</div>
