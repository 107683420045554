import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { FormErrorMatcher } from 'src/app/shared/utils/form-error-matcher';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogModule } from 'src/app/shared/components/alert-dialog/alert-dialog.module';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { SNACK_BAR_DURATION } from 'src/app/shared/models/data-catalogs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../login.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  resetInProgress: boolean = false;

  matcher = new FormErrorMatcher();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private clipboard: Clipboard,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: ['', {validators: [Validators.required, Validators.email]}]
    });
  }

  get emailControl() {
    return this.resetForm.controls.email;
  }

  reset() {
    if (this.resetForm.invalid) {
      return;
    }

    this.resetInProgress = true;

    this.authService
      .resetPassword(this.resetForm.controls.email.value)
      .pipe(
        finalize(() => {
          this.resetInProgress = false;
        }),
      )
      .subscribe(
        () => {
          this.router.navigate(['/login']);
          this.dialog.open(AlertDialogComponent, {
            data: {
              type: 'success',
              message: 'We’ve sent a password reset link to your email address. Please click the link to reset your password.'
            }
          });
        }, (err) => {
          const errorString = this.errorService.parseError(err);
          if (errorString) {
            this._snackBar.open(
              `Error ID: ${errorString}`,
              'Copy',
              { duration: SNACK_BAR_DURATION })
            .onAction()
            .subscribe(() => {
              this.clipboard.copy(errorString);
            });
          } else {
            this._snackBar.open(`Resetting password failed`, null, { duration: SNACK_BAR_DURATION });
          }
        }
      );
  }
}
