import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  activatePlayer,
  cancelCommand,
  getPlayer,
  playerHistory,
  playerSubscription,
  sendDeviceCommand,
  setAllSettings,
  setZoneOutputs
} from '../gql/player-consts';
import { CommandStatus, PlayerKeyDef } from '../models/data-catalogs';
import { AudioOutput, Player, PlayerDynamicData, PlayerSetting } from '../models/models';
import { LocalStoreService } from './localstore.service';
import { ZonesService } from './zones.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  private player: Player = null;
  player$: BehaviorSubject<Player> = new BehaviorSubject<Player>(null);
  playerStateSubscription: Subscription;

  hasUncheckedCommands: boolean;
  hasUncheckedCommands$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private apollo: Apollo,
    private localstoreService: LocalStoreService,
    private zoneService: ZonesService
  ) { }

  hasActivatedPlayer() {
    const playerId = this.localstoreService.getItem('playerId');
    return !!playerId;
  }

  getSavedPlayer() {
    const playerId = this.localstoreService.getItem('playerId');
    if (playerId && !this.player) {
      this.getPlayer(+playerId).subscribe();
      this.zoneService.clear();
    }
  }

  clear() {
    this.player = null;
    this.player$.next(this.player);
    this.zoneService.clear();
    if (this.playerStateSubscription) {
      this.playerStateSubscription.unsubscribe();
    }
  }

  activate(serialNumber: string) {
    return this.apollo.mutate({
      mutation: activatePlayer,
      variables: { serialNumber }
    })
    .pipe(
      map((data: any) => data.data.activatePlayerBySerialNumber),
      tap((player: Player) => {
        if (!!player) {
          this.localstoreService.setItem('playerId', player.id);
          this.player = player;
          this.player$.next(this.player);
          this.hasUncheckedCommands = false;
          this.hasUncheckedCommands$.next(this.hasUncheckedCommands);
          this.playerSub();
          this.zoneService.clear();
        }
      })
    );
  }

  getPlayer(id: number) {
    return this.apollo.query({
      query: getPlayer,
      variables: { id },
      fetchPolicy: 'no-cache'
    })
    .pipe(
      map((data: any) => data.data.player),
      tap((player: Player) => {
        this.localstoreService.setItem('playerId', player.id);
        this.player = player;
        this.player$.next(this.player);
        this.hasUncheckedCommands = false;
        this.hasUncheckedCommands$.next(this.hasUncheckedCommands);
        this.playerSub();
        this.zoneService.clear();
      })
    );
  }

  playerSub() {
    if (this.playerStateSubscription) {
      this.playerStateSubscription.unsubscribe();
    }
    this.playerStateSubscription = this.apollo.subscribe({
      query: playerSubscription,
      variables: {
        playerIdList: [this.player.id]
      }
    })
    .pipe(
      map((data: any) => data.data.deviceStateChanged),
    )
    .subscribe((playerState: PlayerDynamicData) => {

      this.player = playerState.player;
      if (playerState.affectedFields.includes('commands')) {
        this.hasUncheckedCommands = true;
        this.hasUncheckedCommands$.next(this.hasUncheckedCommands);
      }
      if (playerState.affectedFields.includes('zones')) {
        this.zoneService.getZones(this.player.id).subscribe();
      }
      this.player$.next(this.player);
    });
  }

  playerHistory() {
    return this.apollo.query({
      query: playerHistory,
      fetchPolicy: 'no-cache'
    })
    .pipe(
      map((data: any) => data.data.accessablePlayers)
    );
  }

  setZoneAudioOutputs(playerId: number, values: AudioOutput[]) {
    return this.apollo.mutate({
      mutation: setZoneOutputs,
      variables: {
        playerId,
        values
      }
    })
    .pipe(
      map((data: any) => data.data.setZoneAudioOutputs)
    );
  }

  setAllPlayerSettings(playerId: number, settings: PlayerSetting[]) {
    return this.apollo.mutate({
      mutation: setAllSettings,
      variables: {
        playerId,
        values: settings
      }
    })
    .pipe(
      map((data: any) => data.data.setPlayerSettings)
    );
  }

  sendDeviceCommand(command: string, value?: string, serialNumber?: string) {
    return this.apollo.mutate({
      mutation: sendDeviceCommand,
      variables: {
        command,
        value,
        serialNumber: serialNumber || null,
        playerId: this.player.id
      }
    })
    .pipe(
      map((data: any) => data.data.addDeviceCommand)
    );
  }

  cancelCommand(commandId: number) {
    return this.apollo.mutate({
      mutation: cancelCommand,
      variables: {commandId}
    })
    .pipe(
      map((data: any) => data.data.cancelDeviceCommand),
      tap((res) => {
        if (!!res) {
          this.player.deviceCommands.find((command) => command.id === commandId).status = CommandStatus.CANCEL;
          this.player$.next(this.player);
        }
      })
    );
  }

  setCommandCheckStatus(status: boolean) {
    this.hasUncheckedCommands = status;
    this.hasUncheckedCommands$.next(this.hasUncheckedCommands);
  }
}
