import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-added-zone',
  templateUrl: './success-added-zone.component.html',
  styleUrls: ['./success-added-zone.component.scss']
})
export class SuccessAddedZoneComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  assignOutput() {
    this.router.navigate(['/audio']);
  }

  manage() {
    this.router.navigate(['/zones']);
  }

}
