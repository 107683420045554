import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { SNACK_BAR_DURATION } from 'src/app/shared/models/data-catalogs';
import { VenueView } from 'src/app/shared/models/inner-model';
import { Player } from 'src/app/shared/models/models';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { PlayerService } from 'src/app/shared/services/player.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subject, timer } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'name',
    'type',
    'serial',
    'zonesCount',
    'version',
    'attention',
    'cacheCommit',
    'status'
  ];
  dataSource: VenueView[];
  loading: boolean;
  private unSubscribe$: Subject<{}> = new Subject;

  constructor(
    private playerService: PlayerService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.loading = true;
    timer(0, 30000)
    .pipe(
      switchMap(() => this.playerService.playerHistory()
        .pipe(finalize(() => this.loading = false))
      ),
      takeUntil(this.unSubscribe$)
    )
    .subscribe((players: Player[]) => {
      this.dataSource = players.map((player) => {
        const venueView: VenueView = {
          id: player.id,
          name: player.venue.name,
          type: player.zones && player.zones.length ? player.zones[0].mediaType : '',
          serial: player.serialNumber,
          zonesCount: player.zones && player.zones.length ? player.zones.length : 0,
          version: player.buildVersion,
          attention: !!player.attention ? 'Yes' : 'No',
          cacheCommit: player.cacheCommit,
          status: player.online ? 'Online' : 'Offline'
        };
        return venueView;
      });
    });
  }

  savePlayer(id: number) {
    this.playerService.getPlayer(id)
    .subscribe(
      () => {
        this.router.navigate(['/zones/']);
      },
      (err) => {
        const errorString = this.errorService.parseError(err);
        if (errorString) {
          this._snackBar.open(
            `Error ID: ${errorString}`,
            'Copy',
            { duration: SNACK_BAR_DURATION })
          .onAction()
          .subscribe(() => {
            this.clipboard.copy(errorString);
          });
        } else {
          this._snackBar.open(`Player updating fails`, null, { duration: SNACK_BAR_DURATION });
        }
      }
    );
  }

  activate() {
    this.router.navigate(['/activate']);
  }

  ngOnDestroy() {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }

}
