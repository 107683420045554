import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Player } from 'src/app/shared/models/models';

@Component({
  selector: 'app-success-activation',
  templateUrl: './success-activation.component.html',
  styleUrls: ['./success-activation.component.scss']
})
export class SuccessActivationComponent implements OnInit {
  playerName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.data.player) {
      this.playerName = this.data.player.deviceType;
    }
  }

  addZones() {
    this.router.navigate(['/zones/activate']);
  }

  manage() {
    this.router.navigate(['/zones']);
  }

}
