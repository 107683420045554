<div class="dialog-header">
  <h2 mat-dialog-title>Already done</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>
<mat-dialog-content>
  <div class="alert-text"> The player with serial number {{ serialNumber }} has already been activated </div>
</mat-dialog-content>
<div mat-dialog-actions class="action-btns">
  <button mat-dialog-close class="btn">Try again</button>
</div>
