import gql from 'graphql-tag';

export const trackFragment = gql`
  fragment TrackData on Track {
    id
    name
    artist {
      id
      name
    }
    album {
      id
      name
    }
  }
`;

export const playerZoneStateFragmant = gql`
  fragment PZoneStateData on PlayerZoneState {
    track{
      ...TrackData
    }
    expectEndBy
    endEventIsOverdue
    currentTimeslotStart
    currentTimeslotEnd
    diskCacheUsedGB
    diskCacheFreeGB
    memUsedMB
    cachedUptoDate
    countOfNextTrackCommand
    countOfReloadCommand
    countOfPendingCommand
    countOfFailedCommand
  }
${trackFragment}
`;

export const playerFragment = gql`
  fragment PlayerData on Player {
    id
    clientDeviceId
    authToken
    venue {
      id
      name
    }
    state
    online
    lastLoadedAt
    lastPingAt
    loadedFrom
    buildVersion
    captureLogs
    logLevel
    zones {
      id
      name
      mediaType
    }
    networkInterfaces
    playerZoneStates {
      ...PZoneStateData
    }
    serialNumber
    audioOutputs {
      zoneId
      output
    }
    settings {
      zoneId
      setting
      value
    }
    deviceStats{
      service
      key
      value
      description
    }
    deviceType
    cacheCommit
    attention
    deviceCommands {
      command
      value
      status
      id
      description
    }
    needDeviceUpdate
  }
${playerZoneStateFragmant}
`;

export const zoneFragment = gql`
  fragment ZoneData on Zone {
    id
    name
    mediaType
    player {
      id
      online
    }
    playing
    currentTrack {
      ...TrackData
    }
    currentTrackQueue {
      __typename
      ... on ManualTrackQueue {
        id
        tracks {
          id
        }
      }
      ... on OverrideTrackQueue {
        id
        playlist {
          id
          name
        }
        bucket {
          id
          name
        }
      }
      ... on ScheduleTrackQueue {
        id
        playlist {
          id
          name
        }
        bucket {
          id
          name
        }
      }
    }
    subscribedToChannels {
      id
      name
    }
    streamReceivers {
      id
      description
      token
      activationCode
    }
  }
${trackFragment}
`;

export const zoneReceivers = gql`
  fragment ZoneReceivers on Zone {
    streamReceivers {
      id
      description
      token
      activationCode
    }
  }
`
