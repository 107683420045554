import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoComponent } from './video.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CodeDialogModule } from 'src/app/shared/components/code-dialog/code-dialog.module';
import { ZoneReceiversModule } from '../zone-receivers/zone-receivers.module';
import { AudioLinkingComponent } from './audio-linking/audio-linking.component';

const routes = [{
  path: '',
  component: VideoComponent
}];

@NgModule({
  declarations: [
    VideoComponent,
    AudioLinkingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatInputModule,
    ClipboardModule,
    ZoneReceiversModule
  ],
  exports: [
    AudioLinkingComponent
  ]
})
export class VideoModule { }
