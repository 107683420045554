<header id="header">
  <div class="container">
    <div class="brand-name">
      audalize
    </div>
    <div class="project-title">
      MANAGE<span class="bold">CLOUD</span>
    </div>
    <div class="notification-container" (click)="openCommandsDialog()">
      <div [class.unchecked]="hasUncheckedCommand"></div>
      <div class="icon icon-notification"></div>
    </div>
  </div>
</header>
