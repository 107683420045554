<div class="dialog-header">
  <h2 mat-dialog-title>{{zone.name}} Receivers</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>

<mat-dialog-content class="content-container">
  <ng-container *ngIf="!loading; else spinner">
    <div class="receivers-container" *ngIf="receivers?.length; else noReceivers">
      <div *ngFor="let receiver of receivers" class="receiver-item">
        <div class="name">{{ receiver.description || receiver.token }}</div>
        <button class="btn small" (click)="disconnectReceiver(receiver.id)">Disconnect</button>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>

<div mat-dialog-actions class="action-btns">
  <button class="btn assign-btn" (click)="assignReceiver()">Assign receiver</button>
  <button mat-dialog-close class="btn">Cancel</button>
</div>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template>

<ng-template #noReceivers>
  <div class="no-data">
    There are no receivers attached to this zone
  </div>
</ng-template>

