<div class="form-wrapper">
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="form-group" [class.with-error]="emailControl.invalid && (emailControl.dirty || emailControl.touched)">
      <label for="email">User</label>
      <input matInput [errorStateMatcher]="matcher" id="email" inputmode="email" formControlName="email" type="email" placeholder="Enter Email">
    </div>
    <div class="error-wrapper" *ngIf="emailControl.invalid && (emailControl.dirty || emailControl.touched)">
      <mat-error *ngIf="emailControl.hasError('email') && !emailControl.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="emailControl.hasError('required')">
        Email is required
      </mat-error>
    </div>
    <div class="form-group" [class.with-error]="passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)">
      <label for="password">Password</label>
      <input matInput [errorStateMatcher]="matcher" id="password" inputmode="password" formControlName="password" type="password" placeholder="Enter Password">
    </div>
    <div class="error-wrapper" *ngIf="passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)">
      <mat-error *ngIf="passwordControl.hasError('required')">
        Password is required
      </mat-error>
    </div>
    <div class="controls-wrapper">
      <div class="control-buttons">
        <button type="submit" [disabled]="loginForm.invalid || loginInProgress" class="btn signin-btn">Login</button>
      </div>
      <a routerLink="./reset" class="d-block text-center forgot-link">Forgot password?</a>
    </div>
  </form>
</div>
