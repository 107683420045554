<div class="form-wrapper">
  <form [formGroup]="resetForm" (ngSubmit)="reset()">
    <div class="form-group" [class.with-error]="emailControl.invalid && (emailControl.dirty || emailControl.touched)">
      <label for="email">Email</label>
      <input matInput
        [errorStateMatcher]="matcher"
        id="email"
        inputmode="email"
        formControlName="email"
        type="email"
        placeholder="Enter Email"
      />
    </div>
    <div class="error-wrapper" *ngIf="emailControl.invalid && (emailControl.dirty || emailControl.touched)">
      <mat-error *ngIf="emailControl.hasError('email') && !emailControl.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="emailControl.hasError('required')">
        Email is required
      </mat-error>
    </div>
    <div class="controls-wrapper">
      <div class="control-buttons">
        <button type="submit" [disabled]="resetForm.invalid || resetInProgress" class="btn reset-btn">Send new password</button>
      </div>
      <a routerLink=".." class="d-block text-center forgot-link">I remember my password</a>
    </div>
  </form>
</div>
