import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoneReceiversComponent } from './zone-receivers.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CodeDialogModule } from 'src/app/shared/components/code-dialog/code-dialog.module';



@NgModule({
  declarations: [
    ZoneReceiversComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    CodeDialogModule,
  ],
  exports: [
    ZoneReceiversComponent
  ]
})
export class ZoneReceiversModule { }
