<div class="dialog-header">
  <h2 mat-dialog-title>Player Commands</h2>
  <button mat-dialog-close class="btn close-dialog-btn"></button>
</div>
<mat-dialog-content class="content-container">
  <ng-container *ngIf="!loading; else spinner">
    <div *ngIf="dataSource?.length; else noCammand">
      <mat-table class="command-table" [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Command </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.command}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="value">
          <mat-header-cell *matHeaderCellDef> Additional </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.value}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
          <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.status === 'pending'">
            <button class="btn small" (click)="cancelCommand(element.id)">Cancel</button>
            </ng-container>
            <ng-template #statusIcon [ngIf]="element.status !== 'received' && element.status !== 'pending'">
              <div class="icon" [ngClass]="{
                'icon-success': element.status === 'success',
                'icon-error': element.status === 'error',
                'icon-cancel': element.status === 'canceled'
              }"></div>
            </ng-template>
            <ng-template #smallSpinner [ngIf]="element.status === 'received'">
              <mat-spinner [diameter]="20"></mat-spinner>
            </ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </ng-container>
</mat-dialog-content>
<div mat-dialog-actions class="action-btns">
  <button mat-dialog-close class="btn">OK</button>
</div>

<ng-template #spinner>
  <div class="spinner-wrapper">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template>

<ng-template #noCammand>
  <div class="no-data">
    There are no commands for this player
  </div>
</ng-template>
