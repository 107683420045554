import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { LoginComponent } from './login.component';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertDialogModule } from 'src/app/shared/components/alert-dialog/alert-dialog.module';
import { MatDialogModule } from '@angular/material/dialog';

const routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'reset',
    component: ResetPasswordComponent
  }
];

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MatInputModule,
    MatSnackBarModule,
    MatDialogModule,
    AlertDialogModule
  ]
})

export class LoginModule { }
