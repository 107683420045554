import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { IP_REGULAR, PlayerSettingKeys, SNACK_BAR_DURATION, StatsKeys } from 'src/app/shared/models/data-catalogs';
import { Player, PlayerSetting } from 'src/app/shared/models/models';
import { ErrorParserService } from 'src/app/shared/services/error-parcing.service';
import { PlayerService } from 'src/app/shared/services/player.service';
import { Clipboard } from '@angular/cdk/clipboard';

const ALLOWED_EMPTY_KEY = ['ip', 'subnet', 'gateway'];

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {
  playerSub: Subscription;
  networkForm: FormGroup;
  player: Player;
  wgIp: string;

  constructor(
    private formBuilder: FormBuilder,
    private playerService: PlayerService,
    private _snackBar: MatSnackBar,
    private errorService: ErrorParserService,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.networkForm = this.formBuilder.group({
      ip: ['', {validators: [Validators.pattern(IP_REGULAR)]}],
      dhcp: 'false',
      subnet: ['', {validators: [Validators.pattern(IP_REGULAR)]}],
      gateway: ['', {validators: [Validators.pattern(IP_REGULAR)]}],
      dnsA: ['', {validators: [Validators.required, Validators.pattern(IP_REGULAR)]}],
      dnsB: ['', {validators: [Validators.required, Validators.pattern(IP_REGULAR)]}],
      mtu: ['', {validators: [Validators.pattern(/^\d*$/)]}],
    });

    this.playerSub = this.playerService.player$
    .pipe(
      filter((player) => !!player),
    )
    .subscribe((player) => {
      this.player = player;
      if (this.networkForm.untouched) {

        if (!!this.player.settings?.length) {
          const settings: PlayerSetting[] = this.player.settings;
          this.networkForm.patchValue({
            ip: settings.find((x) => x.setting === PlayerSettingKeys.IP)?.value || '',
            dhcp: settings.find((x) => x.setting === PlayerSettingKeys.DHCP)?.value || 'false',
            subnet: settings.find((x) => x.setting === PlayerSettingKeys.SUBNET)?.value || '',
            gateway: settings.find((x) => x.setting === PlayerSettingKeys.GATEWAY)?.value || '',
            dnsA: settings.find((x) => x.setting === PlayerSettingKeys.DNSA)?.value || '',
            dnsB: settings.find((x) => x.setting === PlayerSettingKeys.DNSB)?.value || '',
            mtu: settings.find((x) => x.setting === PlayerSettingKeys.MTU)?.value || '',
          });
          this.disableFields();
        }
      }

      this.wgIp = this.player.deviceStats?.find((stat) => stat.key === StatsKeys.WG)?.value || 'Not defined';
    });

    ALLOWED_EMPTY_KEY.forEach(
      (key) => this.networkForm.controls[key].setValidators([optionalRequiredValidation, Validators.pattern(IP_REGULAR)])
    );
  }

  updateFormValue() {
    if (this.dhcpBoolean && !!this.player.settings?.length) {
      const settings: PlayerSetting[] = this.player.settings;
      this.networkForm.patchValue({
        ip: settings.find((x) => x.setting === PlayerSettingKeys.IP)?.value || '',
        subnet: settings.find((x) => x.setting === PlayerSettingKeys.SUBNET)?.value || '',
        gateway: settings.find((x) => x.setting === PlayerSettingKeys.GATEWAY)?.value || '',
      });
      ALLOWED_EMPTY_KEY.forEach((key) => this.networkForm.controls[key].markAsUntouched());
    }
    this.disableFields();
    ALLOWED_EMPTY_KEY.forEach((key) => this.networkForm.controls[key].updateValueAndValidity());
  }

  get dhcpBoolean() {
    return this.networkForm.controls.dhcp.value === 'true';
  }

  disableFields() {
    if (this.dhcpBoolean) {
      ALLOWED_EMPTY_KEY.forEach((key) => this.networkForm.controls[key].disable());
    } else  {
      ALLOWED_EMPTY_KEY.forEach((key) => this.networkForm.controls[key].enable());
    }
  }

  save() {
    if (this.networkForm.invalid) {
      return;
    }

    const settingsArr: PlayerSetting[] = [];
    const formValue = this.networkForm.value;
    for (const key in formValue) {
      if (ALLOWED_EMPTY_KEY.includes(key) && this.dhcpBoolean) {
        continue;
      }
      settingsArr.push({
        setting: PlayerSettingKeys[key.toUpperCase()],
        value: `${formValue[key]}`
      });
    }

    this.playerService.setAllPlayerSettings(this.player.id, settingsArr)
    .pipe(
      tap(() => this.networkForm.markAsUntouched()),
      switchMap(() => this.playerService.getPlayer(this.player.id))
    )
    .subscribe(() => {
      this._snackBar.open(`Player updated successfully`, null, { duration: SNACK_BAR_DURATION });
    }, (err) => {
      const errorString = this.errorService.parseError(err);
      if (errorString) {
        this._snackBar.open(
          `Error ID: ${errorString}`,
          'Copy',
          { duration: SNACK_BAR_DURATION })
        .onAction()
        .subscribe(() => {
          this.clipboard.copy(errorString);
        });
      } else {
        this._snackBar.open(`Player updating fails`, null, { duration: SNACK_BAR_DURATION });
      }
    });
  }

}

export const optionalRequiredValidation: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  const dhcpControl = (control.parent as FormGroup).controls.dhcp;
  return (dhcpControl.value === 'false' && !control.value.trim()) ? { optionalRequired: true } : null;
};
